<template>
<!--  <div class="content_li" v-loading="loading">-->
  <div class="content_li">
    <div class="shebei">商品属性</div>
    <div class="attributes">
      <div class="container">
        <el-input maxlength="30" v-model="specification" placeholder="请输入属性名称(例如：颜色)" style="width:300px"
                  @keyup.enter.native="addSpecification"></el-input>
        <el-button type="primary" class="margin-btn" @click="addSpecification">添加商品属性
        </el-button>
      </div>

      <div class="sku-container">
        <el-card class="item" v-for="(item, index) in specificationList" :key="index">
          <h4>
            {{ item.attributesName }}
            <el-input maxlength="30" v-model="item.value" size="small" placeholder="请输入属性值（例如：红色）" style="width:200px"
                      @keyup.enter.native="addSpecificationValue(index)"></el-input>
            <el-button type="primary" class="margin-btn" size="small" @click="addSpecificationValue(index)">添加值
            </el-button>
            <el-button type="danger" size="small" @click="deleteSpecification(index)" icon="el-icon-delete" circle>
            </el-button>
          </h4>
          <div class="small-attr">
            <div v-for="(sku, count) in item.smallAttributesClassInfos" :key="sku.id"
                 class="small-attr-list flexRow alignItems justifyContentCenter">
              <el-input v-if="false" size="mini" v-model="sku.samllAttrName"
                        maxlength="30"
                        @keyup.enter.native="handleValueInputBlur(sku)"
                        @blur="handleValueInputBlur(sku)" placeholder="请输入内容" style="width:150px"></el-input>
              <span>{{ sku.samllAttrName }}</span>
              <i @click="deleteSpecificationValue(index,count)" class="el-icon-error"></i>
              <el-button v-if="false" type="danger" size="mini" class="del-margin-btn"
                         @click="deleteSpecificationValue(index,count)" icon="el-icon-delete" circle>
              </el-button>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <div class="shebei">商品Sku表</div>
    <div class="table-content" v-if="tableContentData.length === 0">请先添加商品属性和属性值</div>
    <table v-else style="margin:30px auto" border="0" cellspacing="0">
      <thead>
      <tr>
        <th v-for="(th, index) in tableHeadData" :key="index">{{ th }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(tr,index) in tableContentData" :key="index">
        <td v-for="(td,count) in tr" :key="count" :class="td.type === 'ID'?'noTd': ''">
          <div v-if="td.type=='sku'" class="goods-attr">{{ td.value }}</div>

          <div class="flexRow" v-else-if="td.type==='图片'">
            <div class="up-image">
              <div class="image-list" v-if="td.value != ''">
                <img @click="handleSee(td.value)" :src="td.value" alt/>
              </div>
            </div>
            <el-upload
                action
                accept=".jpg, .jpeg, .png, .gif"
                :file-list="upList"
                list-type="picture-card"
                :show-file-list="false"
                :auto-upload="true"
                :http-request="fnUploadRequest"
            >
              <i class="el-icon-plus" @click="curRowIndex = index"></i>
            </el-upload>
          </div>
          <el-switch v-else-if="td.type==='是否为限时售价'" :active-value="1" :inactive-value="0"
                     v-model="td.value"></el-switch>
          <el-input-number v-else-if="td.type==='售价(元)'" v-model="td.value"
                           :precision="2" :step="0.1" :min="0" :placeholder="`请输入${td.type}`"
                           :controls="false"></el-input-number>
          <el-input-number v-else-if="td.type==='重量(KG)'" v-model="td.value"
                           :precision="2" :step="0.1" :min="0" :placeholder="`请输入${td.type}`"
                           :controls="false"></el-input-number>
          <el-input-number v-else-if="td.type==='新人专享售价(元)'" v-model="td.value"
                           :precision="2" :step="0.1" :min="0" :placeholder="`请输入${td.type}`"
                           :controls="false"></el-input-number>
          <el-input-number v-else-if="td.type==='限时售价(元)'" v-model="td.value"
                           :precision="2" :step="0.1" :min="0" :placeholder="`请输入${td.type}`"
                           :controls="false"></el-input-number>
          <el-input-number v-else-if="td.type==='库存'" :min="0" v-model="td.value" :placeholder="`请输入${td.type}`"
                           :controls="false"></el-input-number>
        </td>
      </tr>
      </tbody>
    </table>
    <div style="height: 30px;"></div>
    <div class="footer-toolbar">
      <el-button @click="handlePrev">上一步</el-button>
      <el-button type="primary" @click="handleSubmit">保 存</el-button>
    </div>

    <el-dialog
        title="提示"
        :visible.sync="messageVisible"
        width="30%"
        :before-close="handleCloseMessage">
      <span>请添加商品属性信息再提交</span>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="messageVisible = false">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>
<script>

export default {
  name: "step-two",
  props: {
    type: {
      type: String,
      default: ''
    },
    goodsId: {
      type: String,
      default: ''
    },
    stepOne: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  watch: {
    goodsId: {
      immediate: true,
      handler(val) {
        if (val) {}
      }
    },
    stepOne: {
      immediate: true,
      handler(val) {
        this.data = val;
        if (this.data.id) {
          this.processeData();
        }
      }
    },
    tableContentData: {
      immediate: true,
      handler(val) {
        if(val.length===0) {
          this.loading = false;
        }
      }
    },
    specificationList: {
      immediate: true,
      handler(val) {
        if(val.length===0) {
          this.tableHeadData = [];
          this.tableContentData = [];
        }
      }
    },
  },
  data() {
    return {
      messageVisible: false, // 提示弹框
      messageInfo: "",// 提示弹框文字
      data: {},
      upList: [],
      specification: "", // 每次添加的规格
      specificationList: [], //规格列表
      currentItem: [], //当行表的一条数据
      currentList: [], //当行表的一条数据
      tableHeadData: [], //表头
      tableContentData: [], //生成后表中的数据
      delectFlag: false, //删除sku数据的标记
      isDelAttr: false, //删除sku商品数据的标记
      loading: false, // 加载状态
    }
  },
  methods: {
    // 编辑查看数据
    processeData() {
      let _this = this;
      // 规格列表
      //简单的深拷贝 不影响原数据 然后过滤掉没有规格值的规格
      _this.specificationList = JSON.parse(JSON.stringify(_this.data.attributesClassInfos))
      let tempList = JSON.parse(JSON.stringify(_this.data.attributesClassInfos)).filter(
          (item) => item.smallAttributesClassInfos.length
      );

      // 重新生成表头
      _this.generateTableHead();

      // 这里改一下数据的结构
      const arr = tempList.map((item) => {
        return item.smallAttributesClassInfos.map((item2) => {
          return {
            type: "sku",
            value: item2.samllAttrName,
            sort: item.sort,
            key: item.attributesName,
            id: item2.id
          };
        });
      });
      // 数据改造成真正的表中数据的结构
      const tableContentData = _this.cartesianProductOf(...arr);
      // 拷贝返回的表格数据
      const attrList = JSON.parse(JSON.stringify(_this.data.attrsAssociateInfos));

      _this.currentList = [];
      _this.tableContentData = [];
      for (let i = 0; i < attrList.length; i++) {
        const v = attrList[i];
        _this.currentItem = [{
          type: "图片",
          value: v.images.split(",")
        }, {
          type: "库存",
          value: v.stock,
        }, {
          type: "售价(元)",
          value: v.sellingPrice / 100,
        }, {
          type: "重量(KG)",
          value: v.weight,
        },{
          type: "新人专享售价(元)",
          value: v.newUserExclusivePrice / 100,
        }, {
          type: "是否为限时售价",
          value: v.limitedSaleStatus,
        }, {
          type: "限时售价(元)",
          state: v.limitedSaleStatus,
          value: v.limitedSale / 100,
        }, {
          type: "ID",
          value: v.smallAttributesIds.split(",")
        }];
        _this.currentList.push(_this.currentItem);
      }

      // 重新渲染表格数据 根据ID来匹配
      for (let i = 0; i < tableContentData.length; i++) {
        const ids = [];

        for (let j = 0; j < tableContentData[i].length; j++) {
          ids.push(tableContentData[i][j].id);
        }

        for (let j = 0; j < _this.currentList.length; j++) {
          for (let k = 0; k < _this.currentList[j].length; k++) {
            if (_this.currentList[j][k].type == "ID") {
              if (_this.currentList[j][k].value.toString() == ids.toString()) {
                tableContentData[i].push(..._this.currentList[j])
              }
            }
          }
        }
      }

      _this.tableContentData.push(...tableContentData);
      // console.log(267,_this.tableContentData)
    },


    // 多数组求笛卡儿积function cartesianProduct(array){
    // 笛卡尔积
    cartesianProductOf() {
      return Array.prototype.reduce.call(
          arguments,
          function (a, b) {
            var ret = [];
            a.forEach(function (a) {
              b.forEach(function (b) {
                ret.push(a.concat(b));
              });
            });
            return ret;
          },
          [[]]
      );
    },
    // 去重
    newArr(arr) {
      return Array.from(new Set(arr))
    },

    // 浏览图片
    handleSee(e) {
      let _this = this;
      _this.dialogImgUrl = e;
      _this.dialogImgVisible = true;
    },
    // 输入框校验
    tableParValueChange(value) {
      if (value === 0) {
        value.error = '不能为空'
      }
    },
    // 添加商品属性
    addSpecification() {
      let _this = this;
      if (!_this.specification) {
        return _this.$message.warning("请填写属性再添加");
      }
      // every()是对数组中每一项运行给定函数，如果该函数对每一项返回true,则返回true。
      // some()是对数组中每一项运行给定函数，如果该函数对任一项返回true，则返回true。
      let repetition = _this.specificationList.some((item) => {
        return item.attributesName === _this.specification;
      });
      // 查看是否有重复属性
      if (repetition) return _this.$message.warning("请勿添加重复的属性");

      // 获取商品属性数据，插入specificationList 属性列表里面
      _this.getAttrData(_this.specification);
    },

    // 添加规格值
    addSpecificationValue(index) {
      let _this = this;
      if (!_this.specificationList[index].value) {
        return _this.$message.warning("请填写规格值再添加");
      }
      // 判断是否有规格值
      let repetition = _this.specificationList[index].smallAttributesClassInfos.some((item) => {
        return _this.specificationList[index].value === item.value;
      });
      if (repetition) return _this.$message.warning("请勿添加重复的规格值");
      // 只有新添加了一个规格 且这个规格之前没有规格值(即下面push之后该规格只有一个规格值) new才会等于true
      // 用于后面表格列表添加一列数据
      // 必须tableContentData有长度才能为true 因为tableContentData没有数据的话 就不用添加一列 而是直接在tableContentData列表push一行数据就行了
      _this.specificationList[index].new =
          !_this.specificationList[index].smallAttributesClassInfos.length && _this.tableContentData.length
              ? true : false;

      // 获取商品属性值数据，插入specificationList.smallAttributesClassInfos 属性值列表里面
      _this.getSmallAttrData(index);

    },

    // 规格值输入框失去焦点或者按回车会在表格中循环修改
    handleValueInputBlur(sku) {
      let _this = this;
      const {id, value} = sku;


      _this.tableContentData.forEach(item => {
        item.forEach(v => {
          if (v.id === id) {
            v.value = value
          }
        })
      })
    },
    // 删除规格值 attrIndex：当前属性的下标  smallAttrIndex：当前属性值的下标
    deleteSpecificationValue(attrIndex, smallAttrIndex) {
      let _this = this;
      if (!_this.tableContentData.length) {
        _this.delSmallAttr(attrIndex, smallAttrIndex);
        return false;
      }
      // smallAttrLength
      // samllAttrName
      // smallAttrId
      // attributesName
      const length = _this.specificationList[attrIndex].smallAttributesClassInfos.length;
      const value = _this.specificationList[attrIndex].smallAttributesClassInfos[smallAttrIndex].samllAttrName;
      const id = _this.specificationList[attrIndex].smallAttributesClassInfos[smallAttrIndex].id;
      const title = _this.specificationList[attrIndex].attributesName;

      const delInfo = {
        attrIndex,
        smallAttrIndex,
        length,
        value,
        id,
        title
      }

      const requestData = {
        id: _this.specificationList[attrIndex].smallAttributesClassInfos[smallAttrIndex].id
      };

      _this.$confirm('此操作将删除该属性值, 是否继续?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.loading = true;
            _this.$api.delSmallAttributesClassInfo(requestData).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.specificationList[attrIndex].smallAttributesClassInfos.splice(smallAttrIndex, 1);
                  _this.delCurrentSmallAttr(delInfo);
                }
              } else {
                _this.$message.error('删除失败');
              }
              _this.loading = false;
            });
          })
          .catch(() => {
            _this.loading = false;
          });
    },

    // 如果有多个值时就删除this.tableContentData中所有带有这个值的那一行
    delCurrentSmallAttr(delInfo) {
      let _this = this;

      // return

      if (delInfo.length > 1) {
        // 如果有多个值时就删除this.tableContentData中所有带有这个值的那一行
        for (let i = 0; i < _this.tableContentData.length; i++) {
          for (let j = 0; j < _this.tableContentData[i].length; j++) {
            if (_this.tableContentData[i][j].id == delInfo.id) {
              _this.tableContentData.splice(i, 1);
              i--;
              break;
            }
          }
        }
      } else {
        // 如果只有一个值 那么就把值从所有数据中删除 就是删除列
        _this.specificationList[delInfo.attrIndex].new = true; //删除掉这个值 那么这个规格没有值了  就等同于是新添加的
        _this.deleteRowData(delInfo.title);
        if (
            _this.tableContentData.length === 1 &&
            _this.tableContentData[0][0].type != "sku"
        ) {
          // 如果删除的是最后的规格和最后一个值就清空所有数据
          _this.tableContentData = [];
          _this.tableHeadData = [];
          // 如果没有数据就没有新值的概念
          _this.specificationList.forEach((item) => {
            item.new = false;
          });
          return false;
        }
        // 重新生成表头
        _this.generateTableHead();
      }
    },

    delSmallAttr(attrIndex, smallAttrIndex) {
      let _this = this;
      const requestData = {
        id: _this.specificationList[attrIndex].smallAttributesClassInfos[smallAttrIndex].id
      };
      _this.$confirm('此操作将删除该属性值, 是否继续?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.loading = true;
            _this.$api.delSmallAttributesClassInfo(requestData).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.specificationList[attrIndex].smallAttributesClassInfos.splice(smallAttrIndex, 1);
                }
              } else {
                _this.$message.error('删除失败');
              }
              _this.loading = false;
            });
          })
          .catch(() => {
            _this.loading = false;
          });
    },

    // 添加一条商品属性
    getSmallAttrData(index) {
      let _this = this;
      _this.loading = true; // 加载状态
      const requestData = {
        samllAttrName: _this.specificationList[index].value,
        attributesId: _this.specificationList[index].id,
        commodityId: _this.data.id,
        sort: _this.specificationList[index].smallAttributesClassInfos.length,
      };
      _this.$api.addSmallAttributesClassInfo(requestData).then(res => {
        if (res.data.code == 200) {
          const data = res.data.data;
          _this.specificationList[index].smallAttributesClassInfos.push({
            samllAttrName: data.samllAttrName, //规格值
            id: data.id,
            attributesId: data.attributesId,
            attributesName: data.attributesName,
            sort: data.sort
          });
          // 插入完毕后清空增加商品属性值的输入框
          _this.specificationList[index].value = "";
          // 生成数据
          _this.generateData(index);
        } else {
          _this.$message.error('添加失败');
        }
        _this.loading = false; // 加载状态
      }).catch(() => {
        _this.loading = false; // 加载状态
      });
    },

    // 生成表格数据
    generateData(attrIndex) {
      let _this = this;
      // 如果商品属性列表的没有长度，则不用生成表格数据
      if (!_this.specificationList.length) return false;

      let flag = _this.specificationList.some((item) => {
        return item.smallAttributesClassInfos.length > 0;
      });
      if (!flag) return false;
      _this.generateTableHead();
      _this.generateTableContent(attrIndex);
    },

    // 处理表格中的数据
    generateTableContent(attrIndex) {
      let _this = this;
      //简单的深拷贝 不影响原数据 然后过滤掉没有规格值的规格
      let tempList = JSON.parse(JSON.stringify(_this.specificationList)).filter(
          (item) => item.smallAttributesClassInfos.length
      );

      //newItem为新添加的规格值项
      const newItem = tempList[attrIndex].smallAttributesClassInfos[tempList[attrIndex].smallAttributesClassInfos.length - 1];
      tempList[attrIndex].smallAttributesClassInfos = [newItem];
      let flag = false; //用于判断是否有新添加的规格
      tempList.forEach((item, count) => {
        if (!item.smallAttributesClassInfos || item.smallAttributesClassInfos.length === 0) return false;
        if (item.new) {
          //如果有新添加的规格  则直接在表中的每一项插入值
          flag = true;
          for (let i = 0; i < _this.tableContentData.length; i++) {
            _this.tableContentData[i].splice(item.sort, 0, {
              type: "sku",
              value: item.smallAttributesClassInfos[0].samllAttrName,
              sort: item.sort,
              key: item.attributesName,
              id: item.smallAttributesClassInfos[0].id
            });
          }
          _this.specificationList[count].new = false; //重置new字段
        }
      });
      if (flag) return false;

      // 这里改一下数据的结构
      const arr = tempList.map((item) => {
        return item.smallAttributesClassInfos.map((item2) => {
          return {
            type: "sku",
            value: item2.samllAttrName,
            sort: item.sort,
            key: item.attributesName,
            id: item2.id
          };
        });
      });

      // 数据改造成真正的表中数据的结构
      const tableContentData = _this.cartesianProductOf(...arr);
      tableContentData.forEach(item => {
        // 这里给表中每行加一些静态数据，如输入框 下拉框等
        item.push(..._this.staticData())
      });

      // 数据加入表格中
      this.tableContentData.push(...tableContentData);
      // console.log(this.specificationList, this.tableContentData, 247);
    },

    // 添加一条商品属性
    getAttrData(name) {
      let _this = this;
      _this.loading = true; // 加载状态
      const requestData = {
        attributesName: name,
        commodityId: _this.data.id,
        sort: _this.specificationList.length+1
      };
      _this.$api.addAttributesClassInfo(requestData).then(res => {
        if (res.data.code == 200) {
          const data = res.data.data;
          _this.specificationList.push({
            smallAttributesClassInfos: [], //规格值(小属性列表)
            sort: this.specificationList.length, //用于后面生成数据时排序
            new: false, //用于判断是否有新添加规格 且这个规格添加规格值之后只有一个规格值才会等于true 用于后面表格列表添加一列数据
            value: "",// 输入框v-model绑定值
            id: data.id, // 数据ID
            commodityId: _this.data.id, // 商品ID
            attributesName: data.attributesName, //规格（属性名称）
          });
          // 插入完毕后清空增加商品属性的输入框
          _this.specification = "";
        } else {
          _this.$message.error("添加失败")
        }
        _this.loading = false; // 加载状态
      }).catch(() => {
        _this.loading = false; // 加载状态
      });
    },


    // 删除规格
    deleteSpecification(attrIndex) {
      let _this = this;
      const attributesName = _this.specificationList[attrIndex].attributesName;
      const tempList = JSON.parse(JSON.stringify(_this.specificationList)); //删之前先保存一份用于后面判断
      // 调删除属性接口
      _this.isDelAttr = false;

      const requestData = {
        id: _this.specificationList[attrIndex].id
      };
      _this.$confirm('此操作将删除该商品属性, 是否继续?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.loading = true;
            _this.$api.delAttributesClassInfo(requestData).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.specificationList.splice(attrIndex, 1);
                  _this.delAttrSuccess(attrIndex, attributesName, tempList);
                }
              } else {
                _this.$message.error('删除失败');
              }
              _this.loading = false;
            });
          })
          .catch(() => {
            _this.loading = false;
          });

      // //删除完让sort重新获取值  不然后面添加规格排序会乱
      // _this.specificationList.forEach((item, index) => {
      //   item.sort = index;
      // });
    },

    // 判断是否删除商品属性成功
    delAttrSuccess(attrIndex, attributesName, tempList) {
      let _this = this;
      // 判断表格是否有数据
      if (!_this.tableContentData.length) {
        return false;
      }

      // 如果当前删除的规格里面没有值就不用在_this.tableContentData中删除数据
      if (!tempList[attrIndex].smallAttributesClassInfos.length) return false;
      // 如果删除的是最后一个规格，则清空所有数据
      if (_this.tableContentData[0][1].type != "sku") {
        _this.tableContentData = [];
        _this.tableHeadData = [];
        return false;
      }
      // 删除表中整列的数据
      _this.deleteRowData(attributesName);
      // 因为删除完一列数据的话 可能会有多行重复的数据 所以需要去重
      for (let i = 0; i < _this.tableContentData.length; i++) {
        for (let j = i + 1; j < _this.tableContentData.length; j++) {
          _this.recursionJudgeValue(i, j, 0, true);
          if (_this.delectFlag) {
            _this.tableContentData.splice(j, 1);
            j--;
          }
        }
      }
      // 重新生成表头
      _this.generateTableHead();
    },

    // 递归判断两行的数据是否一样
    recursionJudgeValue(i, j, count, flag) {
      let _this = this;
      let tempFlag =
          _this.tableContentData[i][count].value == _this.tableContentData[j][count].value;
      let tempCount = count;
      const newFlag = flag && tempFlag;
      if (tempCount < _this.specificationList.length) {
        tempCount++;
        _this.recursionJudgeValue(i, j, tempCount, newFlag);
      } else {
        _this.delectFlag = newFlag;
      }
    },

    // 删除表中一列的数据
    deleteRowData(attributesName) {
      let _this = this;
      for (let i = 0; i < _this.tableContentData.length; i++) {
        value: for (let j = 0; j < _this.tableContentData[i].length; j++) {
          if (_this.tableContentData[i][j].key == attributesName) {
            _this.tableContentData[i].splice(j, 1);
            break value;
          }
        }
      }
    },

    // 生成表头
    generateTableHead() {
      let _this = this;
      _this.tableHeadData = [];
      _this.specificationList.forEach(item => {
        if (item.smallAttributesClassInfos.length) {
          _this.tableHeadData.push(item.attributesName);
        }
      });
      const tableHeads = _this.staticData().map(item => item.type)
      _this.tableHeadData.push(...tableHeads);
    },

    // 表格中的其他td数据
    staticData() {
      return [{
        type: "图片",
        value: [],
      }, {
        type: "库存",
        value: "",
      }, {
        type: "售价(元)",
        value: "",
      },  {
        type: "重量(KG)",
        value: "",
      }, {
        type: "新人专享售价(元)",
        state: 0,
        value: "",
      }, {
        type: "是否为限时售价",
        value: 0,
      }, {
        type: "限时售价(元)",
        state: 0,
        value: "",
      }]
    },

    // 上一步
    handlePrev() {
      let _this = this;
      _this.$emit("handle-prev");
      // _this.$emit("handle-prev", _this.data);
    },
    // 关闭提示弹框
    handleCloseMessage() {
      let _this = this;
      _this.messageVisible = false;
    },
    // 提交
    handleSubmit() {
      let _this = this;

      // console.log('表格数据', _this.tableContentData);
      // console.log('表头', _this.tableHeadData)
      // console.log('规格列表', _this.specificationList)


      if (_this.specificationList.length === 0) {
        return _this.$message.warning("请添加商品属性");
      } else if(_this.tableContentData.length === 0) {
        return _this.$message.warning("请添加商品属性值");
      }

      // return

      // 商品属性关联，选项值 列表
      const attributesClassInfos = [];
      _this.specificationList.forEach(v => {
        attributesClassInfos.push({
          attributesName: v.attributesName,
          commodityId: v.commodityId,
          sort: v.sort,
          id: v.id,
          smallAttributesClassInfos: v.smallAttributesClassInfos,
        });
      })

      // 商品属性配置,表格数据
      const attrsAssociateInfos = [];
      for (let i = 0; i < _this.tableContentData.length; i++) {
        const smallIds = [];
        const combinationName = [];
        const tableJson = {
          checked: 0
        };
        for (let j = 0; j < _this.tableContentData[i].length; j++) {

          if (_this.tableContentData[i][j].type === "sku") {
            smallIds.push(_this.tableContentData[i][j].id);
            combinationName.push(_this.tableContentData[i][j].value);
          }
          if (_this.tableContentData[i][j].type === "售价(元)") {
            tableJson.sellingPrice = _this.tableContentData[i][j].value * 100;
          }
          if (_this.tableContentData[i][j].type === "是否为限时售价") {
            tableJson.limitedSaleStatus = _this.tableContentData[i][j].value;
          }
          if (_this.tableContentData[i][j].type === "重量(KG)") {
            tableJson.weight = _this.tableContentData[i][j].value;
          }
          if (_this.tableContentData[i][j].type === "限时售价(元)") {
            tableJson.limitedSale = _this.tableContentData[i][j].value * 100;
          }
          if (_this.tableContentData[i][j].type === "新人专享售价(元)") {
            tableJson.newUserExclusivePrice = _this.tableContentData[i][j].value * 100;
          }
          if (_this.tableContentData[i][j].type === "库存") {
            tableJson.stock = _this.tableContentData[i][j].value;
          }
          if (_this.tableContentData[i][j].type === "图片") {
            tableJson.images = _this.tableContentData[i][j].value.toString();
          }
        }
        attrsAssociateInfos.push({
          ...tableJson,
          smallAttributesIds: smallIds.toString(),
          combinationName: combinationName.toString()
        })
      }
      // 选择第一个规格为选中
      for (let i = 0; i < attrsAssociateInfos.length; i++) {
        if(i === 0) {
          attrsAssociateInfos[i].checked = 1;
        }else {
          attrsAssociateInfos[i].checked = 0;
        }
      }

      for(let [_, item] of Object.entries(attrsAssociateInfos)){
        if(isNaN(item.limitedSale)) {
          item.limitedSale = 0;
        }
        if(item.images === ""){
          _this.$message.warning("请上传商品Sku表格里的图片");
          return;
        }
        if(item.limitedSaleStatus === 1 && item.limitedSale === 0){
          _this.$message.warning("请填写商品Sku表格里是限时售价的属性价格");
          return;
        }
        if(item.limitedSaleStatus === 0 && item.limitedSale !== 0) {
          _this.$message.warning("不是限时售价的商品，不能填写限时售价");
          return;
        }
        if(item.sellingPrice === 0){
          _this.$message.warning("请填写商品Sku表格里的售价");
          return;
        }
        if(item.weight === 0){
          _this.$message.warning("请填写商品Sku表格里的重量");
          return;
        }
      }

      // 图片处理
      _this.data.commodityImgs = _this.data.commodityImgs.toString();

      if (!_this.data.id) {
        // 将checked为1的商品信息放给外层data
        for(let [_, item] of Object.entries(attrsAssociateInfos)){
          if(item.checked === 1) {
            _this.data.sellingPrice = item.sellingPrice;
            _this.data.limitedSale = item.limitedSale;
          }
        }
        // 将秒杀价格放在外层
        for(let [_, item] of Object.entries(attrsAssociateInfos)){
          if(item.newUserExclusivePrice !== 0) {
            _this.data.newUserExclusivePrice = item.newUserExclusivePrice;
            break;
          }
        }

        _this.$api.addCommodityInfo({
          attributesClassInfos: attributesClassInfos,
          attrsAssociateInfos: attrsAssociateInfos,
          ..._this.data
        }).then(res => {
          if (res.data.code == 200) {
            _this.$message.success("添加成功");
            _this.$router.go(-1);
          }
        });
      } else {
        const requestData = JSON.parse(JSON.stringify(_this.data));
        const oldAttrsAssociateInfos = JSON.parse(JSON.stringify(_this.data.attrsAssociateInfos));
        delete requestData.attributesClassInfos;
        delete requestData.attrsAssociateInfos;

        const oldTable = [];
        // 查找有ID的属性组合数据，重新赋值，再push给oldTable
        for (let [i, item] of Object.entries(attrsAssociateInfos)) {
          for (let [_, oldItme] of Object.entries(oldAttrsAssociateInfos)) {
            if (item.smallAttributesIds === oldItme.smallAttributesIds) {
              oldItme.sellingPrice = item.sellingPrice;
              oldItme.limitedSaleStatus = item.limitedSaleStatus;
              oldItme.limitedSale = item.limitedSale;
              oldItme.newUserExclusivePrice = item.newUserExclusivePrice;
              oldItme.weight = item.weight;
              oldItme.sort = Number(i+1);
              oldItme.stock = item.stock;
              oldItme.images = item.images;
              oldItme.combinationName = item.combinationName;
              oldTable.push(oldItme);
              break;
            }
          }
        }

        const newTable = [];
        // 将新增的数据组合添加到 newTable
        attrsAssociateInfos.forEach(item => {
          let repetition = oldTable.some((v) => {
            return v.smallAttributesIds == item.smallAttributesIds;
          });
          if (!repetition) {
            newTable.push(item);
          }
        })
        // 将newTable和oldTable组合，赋值给新的属性列表
        const newAttrsAssociateInfos = newTable.concat(oldTable);

        for (let i = 0; i < newAttrsAssociateInfos.length; i++) {
          if(i === 0) {
            newAttrsAssociateInfos[i].checked = 1;
          }else {
            newAttrsAssociateInfos[i].checked = 0;
          }
        }

        // 将checked为1的商品信息放给外层data
        for(let [_, item] of Object.entries(newAttrsAssociateInfos)){
          if(item.checked === 1) {
            requestData.sellingPrice = item.sellingPrice;
            requestData.limitedSale = item.limitedSale;
          }
          requestData.newUserExclusivePrice = item.newUserExclusivePrice;
        }

        for(let [_, item] of Object.entries(newAttrsAssociateInfos)){
          if(item.newUserExclusivePrice !== 0) {
            requestData.newUserExclusivePrice = item.newUserExclusivePrice;
            break;
          }
        }

        const editData = {
          attributesClassInfos: attributesClassInfos,
          attrsAssociateInfos: newAttrsAssociateInfos,
          ...requestData
        }

        // console.log('requestData',requestData)
        // console.log('attributesClassInfos',attributesClassInfos)
        // console.log('newAttrsAssociateInfos',newAttrsAssociateInfos)
        // return
        _this.$api.editCommodityInfo(editData).then(res => {
          if (res.data.code == 200) {
            _this.$message.success("修改成功");
            _this.$router.go(-1);
          }
        });
      }
    },

    // 上传start
    handleRemove() {
      let _this = this;
      _this.typeIcon = [];
    },
    handlePictureCardPreview(file) {
      let _this = this;
      _this.dialogImageUrl = file.url;
      _this.dialogVisible = true;
    },
    onExceed() {
      let _this = this;
      _this.$message.error("图片最多上传2张");
    },
    removeImage(e) {
      let _this = this;
      if (e) {
        _this.upList.splice(e, 1);
      } else {
        _this.upData.typeIcon = "";
      }
    },
    onError() {
      let _this = this;
      _this.$message.error("图片大小不能超过2M");
    },
    beforeFileUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      let _this = this;

      if (!isLt2M) {
        _this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isLt2M;
    },

    fnUploadRequest(e) {
      let _this = this;
      // return
      _this.utils.upFile(e.file).then(res => {
        for (let i = 0; i < _this.tableContentData.length; i++) {
          if (i === _this.curRowIndex) {
            for (let j = 0; j < _this.tableContentData[i].length; j++) {
              if (_this.tableContentData[i][j].type === "图片") {
                _this.tableContentData[i][j].value = [];
                _this.tableContentData[i][j].value.push(res.url);
                break;
              }
            }
          }
        }
        _this.upList = [];
        _this.upList.push(res);
      });
    }
    // 上传end
  }
}
</script>

<style scoped lang="less">
.attributes {
  font-size: 14px;
  margin: 30px;
}

.change-name {
  &:hover {
    cursor: pointer;
  }
}

.stepThird {
  .addSpecificaType {
    margin-bottom: 10px;
  }

  .itemTag {
    margin-bottom: 20px;

    .outerBorder {
      width: 30%;
      border: 1px solid #eee;
      border-radius: 5px;
      padding: 10px;
      box-sizing: border-box;
      position: relative;

      .close {
        position: absolute;
        right: 0;
        top: 0;

        i {
          font-size: 20px;
          cursor: pointer;
        }
      }

      .inputMar {
        margin-bottom: 10px;
      }
    }
  }
}

.stepFour {
  width: 100%;
  margin: auto;

  &__name {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #eee;
    display: flex;
    align-items: center;

    .item {
      flex: 1;
      width: 120px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 14px;
      border-right: 1px solid #eee;
      padding: 0 10px;
      box-sizing: border-box;
    }
  }

  &__box {
    &__panel {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #eee;
      display: flex;
      align-items: center;
      //padding: 10px 0;
      .item {
        flex: 1;
        width: 120px;
        //height: 50px;
        //line-height: 50px;

        text-align: center;
        font-size: 14px;
        border-right: 1px solid #eee;
        padding: 0 10px;
        box-sizing: border-box;
      }
    }
  }
}

.rigEnd {
  display: flex;
  justify-content: flex-end;

  .previousBtn {
    color: #333;
    margin-right: 20px;
  }

  .saveBtn {
    width: 200px;
    height: 46px;
  }
}


/deep/ .up-image .image-list img {

  width: 80px;
  height: 80px;
  position: relative;
  cursor: pointer;
  margin: 10px;
}

/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
  position: relative;
  margin: 10px 10px 10px 0;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;

}

.el-icon-plus {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  line-height: 80px;
}

.upload-box {
  margin: 10px 0;
}

.shebei {
  padding: 20px 30px;
  font-weight: 900;
  font-size: 14px;
  background: #f7fafc;
}

.footer-toolbar {
  position: fixed;
  bottom: 0;
  z-index: 100;
  //left: 0;
  right: 0;
  width: 89.5%;
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  border-top: 2px solid #eee;
  padding: 10px 40px;
  box-sizing: border-box;
  text-align: right;
}

.content_li {
  padding: 0;
}

.sku-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 0;
}

.sku-container .item {
  width: 32%;
  margin: 10px 0;
}

.sku-container .item .checkbox-group {
  display: flex;
}

.sku-container .item .add-attr {
  width: 100%;
  margin-top: 10px;
}


.sku-container .item:last-child:nth-child(3n-1) {
  margin-right: 34% !important;
}

/deep/ .el-form-item {
  width: 100%;
}

/deep/ .el-input-number.is-without-controls .el-input__inner {
  text-align: left;
}

/deep/ .el-input-number {
  width: 100%;
}

.container {
  padding-bottom: 20px;
}

td,
th {
  border: 1px solid #999999;
  padding: 4px;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
}

th {
  padding: 12px 10px;
  background: rgb(236, 243, 252);
  font-weight: 600;
}

.margin-btn {
  margin-left: 10px;
}

.small-attr {
  display: flex;
  flex-warp: warp;
  margin: 20px 0;
}

.del-margin-btn {
  margin-left: 10px;
  margin-right: 20px;
}

/deep/ .el-icon-error {
  font-size: 20px;
  color: #fa6565;
  cursor: pointer;
  margin-left: 5px;
}

.small-attr {
  flex-wrap:wrap;
}

.small-attr-list {
  margin-right: 10px;

}
.goods-attr {
  //min-width: 10px;
  max-width : 300px;
  padding: 10px;
}
.table-content {
  margin: 30px;
  font-size: 14px;
  color: #999999;
}
.noTd{
  border:0 !important;
}
</style>
