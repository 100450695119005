<template>
  <div class="content_li">
  <div class="content">
    <el-breadcrumb class="xiaxia" separator="/">
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/commodityInfo' }">商品信息</el-breadcrumb-item>
      <el-breadcrumb-item v-if="type == 'edit'">编辑商品</el-breadcrumb-item>
      <el-breadcrumb-item v-if="type == 'add'">新增商品</el-breadcrumb-item>
      <el-breadcrumb-item v-if="type == 'details'">商品详情</el-breadcrumb-item>
    </el-breadcrumb>

    <step-one v-show="step == 1" :type="type" @one-submit="oneSubmit" :goodsId="goodsId"/>
    <step-two v-show="step == 2" :type="type" :stepOne="upData" @submit-ok="submitOk" @handle-prev="handlePrev"/>
<!--    <step-one v-show="step == 1" :type="type" :stepTwo="upData" @one-submit="oneSubmit" :goodsId="goodsId"/>-->
<!--    <step-two v-show="step == 2" :type="type" :stepOne="upData" @submit-ok="submitOk" @handle-prev="handlePrev"/>-->
  </div>
  </div>
</template>

<script>
import StepOne from "./step-one";
import StepTwo from "./step-two";
export default {
  name: "index",
  components: {StepTwo, StepOne},
  mounted() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    if (this.$route.query.goodsId) {
      // const data = JSON.parse(decodeURIComponent(this.$route.query.goodsId));
      this.goodsId = this.$route.query.goodsId;
      // this.upData = JSON.parse(decodeURIComponent(this.$route.query.data));
    }
  },
  data() {
    return {
      step: 1,
      type: "",
      exchange: 0,
      goodsId: "",
      upData: {},
    }
  },
  methods: {
    oneSubmit(value) {
      let _this = this;
      _this.upData = value;
      _this.step = 2;
    },
    submitOk() {
      let _this = this;
      // _this.$emit("submit-ok");
      _this.$router.go(-1);
    },
    handlePrev(val) {
      let _this = this;
      _this.step = 1;
      _this.upData = val;
    },
  }
}
</script>

<style scoped>

</style>
